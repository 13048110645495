<template>
  <div class="task_hall_container">
    <div class="task_hall_title">
      <div class="logo">
        <img src="@/assets/images/task-manager.png" alt="认证审核">
      </div>
      <div style="margin-left: 10px;">企业认证审核</div>
    </div>

    <el-table
      :data="tableList"
      class="table_border"
      :cell-style="cellStyle"
      v-loading="isLoading">
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="100">
      </el-table-column>
      <el-table-column
        label="机构名称"
        prop="companyName">
      </el-table-column>
      <el-table-column
        label="联系方式"
        prop="companyTelephone">
      </el-table-column>
      <el-table-column
        label="邮箱"
        prop="companyEmail">
      </el-table-column>
      <el-table-column
        label="状态"
        width="120">
        <template slot-scope="scope">
          <el-tag
            size="small"
            v-if="scope.row.authing === COMPANY_STATUS.VERIFIED">
            已认证
          </el-tag>
          <el-tag
            size="small"
            v-else
            type="danger">
            未认证
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="80">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="toDetail(scope.row.id)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <pagination
      @paginationChange="paginationChange"
      :pageInfo="pageInfo">
    </pagination>
  </div>
</template>

<script>
import api from '@/api/index'
import Pagination from '@/components/pagination/index.vue'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    Pagination
  },
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      isLoading: false,
      tableList: []
    }
  },
  computed: {
    COMPANY_STATUS () {
      return globalVar.COMPANY_STATUS
    }
  },
  created () {
    this.getList()
  },
  methods: {
    cellStyle () {
      return 'height: 48px; padding: 0px;'
    },
    paginationChange (newNum) {
      this.pageInfo.pageNum = newNum
      this.getList()
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    },
    toDetail (id) {
      this.$router.push(`/admin/certification-detail/${id}`)
    },
    // 获取企业认证列表
    getList () {
      this.isLoading = true
      api.authList({
        ...this.pageInfo,
        authing: 1
      }).then(res => {
        this.isLoading = false
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.pageInfo.total = total
          this.tableList = list
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('请求数据失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.task_hall_container {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  padding: 0 30px;
  overflow: auto;
  .task_hall_title {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
  .table_border {
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    .el-table__cell {
      padding: 0;
    }
  }
}
</style>
